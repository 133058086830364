<template>
  <en-drawer :model-value="modelValue" title="选择付款单据" direction="btt" size="70%" @close="$emit('update:model-value', false)">
    <flex-box>
      <template #default="{ height }">
        <form-query :model="form.data" :method="table.get">
          <en-form-item label="付款搜索">
            <en-input v-model="form.data.quickSearch" placeholder="付款对象/单号"></en-input>
          </en-form-item>
        </form-query>

        <table-dynamic
          code="PYBE"
          :height="height"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          showSelectable
          @selection-change="table.selection.change"
          :ref="setRef('tableRef')"
          :config="table.config"
        >
        </table-dynamic>
      </template>
    </flex-box>

    <template #footer>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean as PropType<boolean>,
    selectedData: Array as PropType<EnocloudSettlementDefinitions['PaymentPayableDto'][]>,
    payeeId: Number as PropType<number>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value?: EnocloudSettlementDefinitions['PayableQueryDto'][][]) => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          await this.table.get()
          this.nextTick(() => {
            if (!this.selectedData) return
            for (const item of this.table.data) {
              this.refs.tableRef.toggleRowSelection(item, this.selectedData.map((sd) => sd.id).includes(item.id))
            }
          })

       
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          statusCode: 'P',
          quickSearch: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/query',
            data: 'array',
            loading: true,
            pagination: true,
            init: true,
            params(params) {
              params.payload = Object.assign({ payeeId: this.payeeId }, this.form.data)
            }
          }
        },
        children: {
          selection: {
            data: [] as EnocloudSettlementDefinitions['PayableQueryDto'][],
            change(rows: EnocloudSettlementDefinitions['PayableQueryDto'][]) {
              this.table.selection.data = rows
            }
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          DOCUMENT_COMMENT: { header: { filter: { type: 'text', field: 'documentComment' } } },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'typeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => ((params.paths = ['PABTYPE']), (params.payload = { excludes: ['POLRBT'] }))
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['documentStartDate', 'documentEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          DOCUMENT_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['documentStartDate', 'documentEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          PREPARED_BY: { header: { filter: { type: 'text', field: 'preparedByName' } } }
        }
      },
      footer: {
        confirm: {
          async click() {
            this.emit('confirm', this.table.selection.data)
            this.emit('update:model-value', false)
          }
        }
      }
    }
  }
})
</script>
